<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAdd" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Add Major</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('false')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Nama major"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  rounded
                  :counter="100"
                  v-model="nameMajor"
                  :error-messages="errMsg"
                  @input="$v.nameMajor.$touch()"
                  @blur="$v.nameMajor.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Kode Majors"
                  required
                  outlined
                  hide-details="auto"
                  dense
                  rounded
                  v-model="codeMajor"
                  :error-messages="codeErr"
                  @input="$v.codeMajor.$touch()"
                  @blur="$v.codeMajor.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-select
                  outlined
                  rounded
                  dense
                  hide-details="auto"
                  item-text="nama"
                  item-value="id"
                  v-model="idUniv"
                  :error-messages="univErr"
                  :items="universitas.data"
                  label="University"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="addMajor">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  name: "addMajor",
  mixins: [validationMixin],
  validations: {
    nameMajor: {
      required,
      maxLength: maxLength(100)
    },
    codeMajor: {
      required
    },
    idUniv: {
      required
    }
  },
  props: ["dialogAdd"],

  computed: {
    ...mapState({
      universitas: state => state.campus.universitas
    }),

    errMsg() {
      const errors = [];
      if (!this.$v.nameMajor.$dirty) return errors;
      !this.$v.nameMajor.maxLength &&
        errors.push("Nama jurusan maksimal 100 karakter!");
      !this.$v.nameMajor.required && errors.push("Nama jurusan harus di isi!");
      return errors;
    },
    codeErr() {
      const errors = [];
      if (!this.$v.codeMajor.$dirty) return errors;
      !this.$v.codeMajor.required && errors.push("Kode jurusan harus di isi!");
      return errors;
    },
    univErr() {
      const errors = [];
      if (!this.$v.idUniv.$dirty) return errors;
      !this.$v.idUniv.required && errors.push("Universitas harus di isi!");
      return errors;
    }
  },
  data() {
    return {
      nameMajor: "",
      codeMajor: "",
      idUniv: ""
    };
  },
  mounted() {
    this.$store.dispatch("campus/listUniversitas");
  },
  methods: {
    addMajor() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v.$invalid);
        return;
      } else {
        this.$store
          .dispatch("campus/createMajors", {
            nama: this.nameMajor,
            kode: this.codeMajor,
            universitas_id: this.idUniv
          })
          .then(data => {
            if (data.data.status == "success") {
              this.nameMajor = "";
              this.codeMajor = "";
              this.idUniv = "";
              this.$v.$reset();
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: data.data.message,
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            } else {
              console.log(data);
              Swal.fire({
                icon: "error",
                title: data.data.data.errors.nama[0],
                showConfirmButton: false,
                position: "top",
                toast: true,
                timer: 3000
              });
            }
          });
      }
    }
  }
};
</script>

<style></style>
