<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card class="radius-card _60-w" :loading="loading" min-height="150px">
        <v-toolbar flat dense> Majors </v-toolbar>
        <v-card-title class="pa-3">
          <v-btn outlined rounded color="blue" @click="dialogAdd = true"
            ><v-icon>mdi-plus</v-icon> Add Majors</v-btn
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            dense
            single-line
            solo
            rounded
            hide-details
          ></v-text-field>
        </v-card-title>
        <AddMajors @false="closeDialog" v-bind:dialogAdd="dialogAdd" />

        <div class="mt-10" v-if="majors">
          <v-data-table
            :headers="headers"
            hide-default-footer
            :items="majors.data"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="cyan"
                  class="mr-2"
                  @click.prevent="updateMajors(item)"
                >
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  color="red"
                  @click.prevent="deleteMajors(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <div class="text-center pt-2"></div>
          <UpdateMajors
            @false="closeDialog"
            v-bind:dialogUpdate="dialogUpdate"
            v-bind:major="major"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import AddMajors from "../../components/Admin/Modal/addMajors.vue";
import UpdateMajors from "../../components/Admin/Modal/updateMajors.vue";

export default {
  name: "MajorsManagement",

  components: {
    AddMajors,
    UpdateMajors
  },

  computed: {
    ...mapState({
      majors: state => state.campus.majors
    })
  },

  data() {
    return {
      dialogAdd: false,
      dialogUpdate: false,
      major: null,
      search: "",
      loading: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "NAME", value: "nama" },
        { text: "CODE", value: "kode" },
        { text: "ACTIONS", value: "actions" }
      ]
    };
  },

  mounted() {
    this.fetchMajors(); //get all data Majors when load page
  },

  methods: {
    fetchMajors() {
      //get all data Majors fucntion
      this.loading = true;
      this.$store.dispatch("campus/listMajors").then(() => {
        this.loading = false;
      });
    },

    updateMajors(item) {
      this.major = item;
      this.dialogUpdate = true;
    },

    deleteMajors(item) {
      //delete Majors function
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete Majors
          this.$store
            .dispatch("campus/deleteMajors", { id: item.id })
            .then(() => {
              this.fetchMajors(); //if success refetch data all Majors
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    },

    closeDialog() {
      //close dialog
      this.dialogAdd = false; //set variable dialog to false
      this.dialogUpdate = false; //set variable dialog to false
      this.fetchMajors(); //and refetch data Majors
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 60%;
}
</style>
